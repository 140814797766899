<template>
  <div class="content-wrapper view-doctor-dashboard pt-3">
    <div class="content">
      <div class="row ml-0" style="max-width: 100%">
        <div class="col-md-5">
          <!-- month calendar -->
          <div class="h100-lg">
            <div class="table-sticky-top">
              <b-card no-body>
                <b-collapse id="doctorCalendar" v-model="isCalendarOpen">
                  <div class="row">
                    <div class="col-12">
                      <b-calendar
                        v-model="dateDefault"
                        class="col border rounded p-1"
                        locale="th-TH"
                        :hide-header="true"
                        block
                      ></b-calendar>
                    </div>
                  </div>
                </b-collapse>
              </b-card>
              <div class="col-12 p-0">
                <!-- table queue treatment -->
                <b-card no-body>
                  <b-table
                    class="mb-0 tx-queue-table"
                    hover
                    small
                    sort-icon-left
                    outlined
                    no-border-collapse
                    sort-by="checkinDt"
                    sticky-header="100%"
                    :items="tableCheckinList"
                    :fields="tableHeaders"
                    head-variant="light"
                    @row-clicked="rowClick"
                    :tbody-tr-class="rowClass + ' border-bottom'"
                  >
                    <template v-slot:cell(index)="row"
                      >{{ row.index + 1 }}.</template
                    >
                    <template v-slot:cell(start)="row">
                      {{ checkTimeAppointment(row.item) }}
                    </template>
                    <template v-slot:cell(checkinDt)="row">
                      {{
                        row.item.checkinDt
                          ? formatTime(row.item.checkinDt)
                          : "-"
                      }}
                    </template>
                    <template v-slot:cell(checkinStatus)="row">
                      <span
                        :class="{
                          onTime: row.value == 1,
                          early: row.value == 2,
                          late: row.value == 3,
                          walkIn: row.value == 4,
                        }"
                        >{{ row.item.checkinStatusName }}</span
                      >
                    </template>
                    <template v-slot:cell(note)="row">
                      {{ row.item.checkinNote }}
                    </template>
                  </b-table>
                  <b-button
                    v-if="!isNoQueue"
                    v-b-toggle.txQueueColorsDetail
                    size="sm"
                    class="m-3"
                    variant="outline-info"
                  >
                    <i class="fas fa-palette"></i> ความหมายของสีในคิว
                  </b-button>
                  <div v-else class="text-muted text-center p-2">
                    ไม่มีคิวรอตรวจ
                  </div>
                  <b-collapse id="txQueueColorsDetail" class>
                    <b-col>
                      <b-table-simple hover small caption-top>
                        <b-tr variant="purple ">
                          <b-td class="text-center border-white rounded">
                            อยู่ในคิวรอตรวจนานกว่า
                            <strong>45 นาที</strong>
                          </b-td>
                        </b-tr>
                        <b-tr variant="red">
                          <b-td class="text-center border-white rounded">
                            อยู่ในคิวรอตรวจ
                            <strong>30-45 นาที</strong>
                          </b-td>
                        </b-tr>
                        <b-tr variant="yellow">
                          <b-td class="text-center border-white rounded">
                            อยู่ในคิวรอตรวจ
                            <strong>15-30 นาที</strong>
                          </b-td>
                        </b-tr>
                        <b-tr variant="green">
                          <b-td class="text-center border-white rounded">
                            อยู่ในคิวรอตรวจน้อยกว่า
                            <strong>15 นาที</strong>
                          </b-td>
                        </b-tr>
                      </b-table-simple>
                    </b-col>
                  </b-collapse>
                </b-card>
                <b-collapse
                  id="dashboardNotation"
                  class="pb-1"
                  v-model="isCalendarOpen"
                >
                  <AdsCard></AdsCard>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="row">
            <div class="col-12">
              <b-card body-class="p-2">
                <fullCalendar
                  ref="calendar"
                  defaultView="resourceTimeGridDay"
                  :themeSystem="themeSystem"
                  height="auto"
                  :defaultDate="dateDefault"
                  :header="header"
                  :titleFormat="titleFormat"
                  :minTime="openingTime"
                  :maxTime="closingTime"
                  :slotDuration="slotDuration"
                  :plugins="calendarPlugins"
                  timeZone="Asia/Bangkok"
                  :locale="locales"
                  :slotLabelFormat="slotLabelFormat"
                  :resources="getDoctorRooms"
                  :events="doctorAppointmentList"
                  :droppable="false"
                  :editable="false"
                  :selectable="false"
                  :eventDurationEditable="false"
                  :nowIndicator="true"
                  :schedulerLicenseKey="schedulerLicenseKey"
                  :customButtons="customButtons"
                  :scrollTime="scrollTime"
                  :allDaySlot="false"
                  :handleWindowResize="true"
                  @windowResize="fcWindowResize"
                  @eventClick="fcAppointmentClick"
                  :eventRender="renderCalendarEvents"
                  :viewSkeletonRender="onViewSkeletonRender"
                ></fullCalendar>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal dialog -->
    <CustomerModal
      ref="customerModal"
      @openProfileCustomerModal="openProfileCustomerModal"
    ></CustomerModal>
    <searchCustomerModal
      ref="searchCustomerModal"
      @openProfileCustomerModal="openProfileCustomerModal"
      @openCustomerModal="openCustomerModal"
    ></searchCustomerModal>
    <ProfileCustomerModal
      ref="profileCustomerModal"
      @openCustomerModal="openCustomerModal"
    ></ProfileCustomerModal>
    <NextVisitModal ref="nextVisitModal"></NextVisitModal>
    <PaymentHistoryModal
      ref="paymentHistoryModal"
      mode="doctor"
      :tableCheckinList="tableCheckinList"
    ></PaymentHistoryModal>
    <ColorSidebar ref="colorSidebar" />
    <Dialog ref="Dialog"></Dialog>
    <Notification></Notification>

    <TreatmentPlanModal ref="treatmentPlanModal" />
    <CariesRiskModal ref="cariesRiskModal" />

    <div id="appointmentPopover" class="d-none">
      <div v-if="lastFcAppointmentSelected">
        <div v-if="isPatientOne" class="row">
          <div class="col d-flex">
            <div class="align-self-stretch small-text">
              <p class="mb-1 mt-1">
                {{ lastFcAppointmentSelected.extendedProps.treatmentPeriod }}
              </p>
              <hr class="my-1" />

              <h6 class="narrow-spacing mb-0">
                <strong>
                  คนไข้ใหม่
                  <br />
                  ไม่มีประวัติ
                </strong>
              </h6>
              <hr class="my-1" />
              <p class="mb-1 narrow-spacing">
                แพทย์:
                {{ lastFcAppointmentSelected.extendedProps.doctorName }}
              </p>
              <p class="mb-1 narrow-spacing">
                <strong
                  v-html="
                    lastFcAppointmentSelected.extendedProps.treatmentTitle
                  "
                ></strong>
              </p>
              <p
                class="mb-1 narrow-spacing"
                v-if="lastFcAppointmentSelected.extendedProps.note"
              >
                โน๊ต: {{ lastFcAppointmentSelected.extendedProps.note }}
              </p>
              <b-button
                v-if="lastFcAppointmentSelected.extendedProps.rmdId"
                id="btnReminder"
                ref="btnReminder"
                class="border-0 mb-1"
                block
                :style="{
                  'background-color': findReminderObj(
                    lastFcAppointmentSelected.extendedProps.rmdId
                  ).desc,
                }"
                size="sm"
                disabled
              >
                {{
                  findReminderObj(lastFcAppointmentSelected.extendedProps.rmdId)
                    .label
                }}
              </b-button>
              <b-button
                id="btnStatus"
                ref="btnStatus"
                class="border-0"
                block
                :style="{
                  'background-color':
                    lastFcAppointmentSelected.extendedProps.statusColor,
                }"
                size="sm"
                disabled
              >
                {{
                  findStatusName(
                    lastFcAppointmentSelected.extendedProps.statusId
                  ).label
                }}
              </b-button>
            </div>
          </div>
        </div>
        <div v-else-if="isPatientNull" class="row">
          <div class="col d-flex">
            <div class="align-self-stretch small-text">
              <p class="mb-1 mt-1">
                {{ lastFcAppointmentSelected.extendedProps.treatmentPeriod }}
              </p>
              <hr class="my-1" />
              <p
                class="mb-1 narrow-spacing"
                v-if="lastFcAppointmentSelected.extendedProps.note"
              >
                <strong>{{
                  lastFcAppointmentSelected.extendedProps.note
                }}</strong>
              </p>
              <p
                class="mb-1 narrow-spacing"
                v-if="lastFcAppointmentSelected.extendedProps.doctor"
              >
                แพทย์: {{ lastFcAppointmentSelected.extendedProps.doctorName }}
              </p>
            </div>
          </div>
        </div>
        <div
          v-else-if="lastFcAppointmentSelected.extendedProps.linkedWithMFD"
          class="row"
        >
          <div class="col-7 pl-3 pr-0 d-flex">
            <div class="align-self-stretch small-text">
              <p class="mb-1 mt-1">
                {{ lastFcAppointmentSelected.extendedProps.treatmentPeriod }}
                <i class="fas fa-info" id="appointment-info"></i>
              </p>
              <hr class="my-1" />
              <p class="mb-0">
                DN: {{ lastFcAppointmentSelected.extendedProps.dn }}
              </p>
              <p
                v-if="lastFcAppointmentSelected.extendedProps.existDn"
                class="mb-1 description text-muted"
              >
                DN(เดิม):
                {{ lastFcAppointmentSelected.extendedProps.existDn }}
              </p>
              <p
                v-if="
                  lastFcAppointmentSelected.extendedProps.patient.titleNameTh
                "
                class="mb-0"
              >
                {{
                  lastFcAppointmentSelected.extendedProps.patient.titleNameTh
                }}
              </p>
              <h6 class="narrow-spacing mb-0">
                <strong>
                  {{
                    lastFcAppointmentSelected.extendedProps.patient.firstNameTh
                  }}
                  <br />
                  {{
                    lastFcAppointmentSelected.extendedProps.patient.lastNameTh
                  }}
                </strong>
              </h6>
              <p
                class="norrow-spacing mb-0"
                v-if="lastFcAppointmentSelected.extendedProps.patient.dob"
              >
                อายุ:
                {{
                  calAge(lastFcAppointmentSelected.extendedProps.patient.dob)
                }}
                ปี
              </p>
              <hr class="my-1" />
              <p class="mb-1 narrow-spacing">
                โทร: {{ lastFcAppointmentSelected.extendedProps.phoneNo }}
              </p>
              <hr class="my-1" />
              <p class="mb-1 narrow-spacing">
                แพทย์:
                {{ lastFcAppointmentSelected.extendedProps.doctorName }}
              </p>
              <p class="mb-1 narrow-spacing">
                <strong
                  v-html="
                    lastFcAppointmentSelected.extendedProps.treatmentTitle
                  "
                ></strong>
              </p>
              <p
                class="mb-1 narrow-spacing"
                v-if="lastFcAppointmentSelected.extendedProps.note"
              >
                โน๊ต: {{ lastFcAppointmentSelected.extendedProps.note }}
              </p>
              <b-button
                v-if="lastFcAppointmentSelected.extendedProps.rmdId"
                id="btnReminder"
                ref="btnReminder"
                class="border-0 mb-1"
                block
                :style="{
                  'background-color': findReminderObj(
                    lastFcAppointmentSelected.extendedProps.rmdId
                  ).desc,
                }"
                size="sm"
              >
                {{
                  findReminderObj(lastFcAppointmentSelected.extendedProps.rmdId)
                    .label
                }}
              </b-button>
              <b-button
                id="btnStatus"
                ref="btnStatus"
                class="border-0"
                block
                :disabled="
                  [5, 6, 7, 8].includes(
                    lastFcAppointmentSelected.extendedProps.statusId
                  )
                "
                :style="{
                  'background-color':
                    lastFcAppointmentSelected.extendedProps.statusColor,
                }"
                size="sm"
              >
                {{
                  findStatusName(
                    lastFcAppointmentSelected.extendedProps.statusId
                  ).label
                }}
              </b-button>
            </div>
          </div>
          <div class="col-5 d-flex justify-content-end">
            <b-button-group vertical class="align-self-stretch">
              <b-button id="btnMorFunDee" variant="primary">
                <img style="width: auto; height: 26px" :src="MorFunDeeLogo" />
                <br />
                Mor <br />
                Fun <br />Dee
              </b-button>
              <b-button id="btnCopyLink" variant="secondary">
                <i class="fas fa-copy"></i>
                <br />
                Copy Link
              </b-button>

              <b-button id="btnPatientDetail" class="btn btn-info">
                <i class="fas fa-info"></i>
                <br />ประวัติ
              </b-button>
            </b-button-group>
          </div>
        </div>
        <div v-else class="row">
          <div class="col-7 pl-3 pr-0 d-flex">
            <div class="align-self-stretch small-text">
              <p class="mb-1">
                {{ lastFcAppointmentSelected.extendedProps.treatmentPeriod }}
              </p>
              <hr class="my-1" />
              <p class="mb-0">
                DN: {{ lastFcAppointmentSelected.extendedProps.dn }}
              </p>
              <p
                v-if="lastFcAppointmentSelected.extendedProps.existDn"
                class="mb-1 description text-muted"
              >
                DN(เดิม):
                {{ lastFcAppointmentSelected.extendedProps.existDn }}
              </p>
              <p
                v-if="
                  lastFcAppointmentSelected.extendedProps.patient.titleNameTh
                "
                class="mb-0"
              >
                {{
                  lastFcAppointmentSelected.extendedProps.patient.titleNameTh
                }}
              </p>
              <h6 class="narrow-spacing mb-0">
                <strong>
                  {{
                    lastFcAppointmentSelected.extendedProps.patient.firstNameTh
                  }}
                  <br />
                  {{
                    lastFcAppointmentSelected.extendedProps.patient.lastNameTh
                  }}
                </strong>
              </h6>
              <p
                class="norrow-spacing mb-0"
                v-if="lastFcAppointmentSelected.extendedProps.patient.dob"
              >
                อายุ:
                {{
                  calAge(lastFcAppointmentSelected.extendedProps.patient.dob)
                }}
                ปี
              </p>
              <hr class="my-1" />

              <p class="mb-1 narrow-spacing">
                แพทย์:
                {{ lastFcAppointmentSelected.extendedProps.doctorName }}
              </p>
              <p class="mb-1 narrow-spacing">
                <strong
                  v-html="
                    lastFcAppointmentSelected.extendedProps.treatmentTitle
                  "
                ></strong>
              </p>
              <p
                v-if="lastFcAppointmentSelected.extendedProps.note"
                class="mb-1 narrow-spacing"
              >
                <strong>
                  โน๊ต:
                  {{ lastFcAppointmentSelected.extendedProps.note }}
                </strong>
              </p>
              <b-button
                v-if="lastFcAppointmentSelected.extendedProps.rmdId"
                id="btnReminder"
                ref="btnReminder"
                class="border-0 mb-1"
                block
                disabled
                :style="{
                  'background-color': findReminderObj(
                    lastFcAppointmentSelected.extendedProps.rmdId
                  ).desc,
                }"
                size="sm"
              >
                {{
                  findReminderObj(lastFcAppointmentSelected.extendedProps.rmdId)
                    .label
                }}
              </b-button>
              <b-button
                id="btnStatus"
                ref="btnStatus"
                class="border-0"
                block
                disabled
                :style="{
                  'background-color':
                    lastFcAppointmentSelected.extendedProps.statusColor,
                }"
                size="sm"
              >
                {{
                  findStatusName(
                    lastFcAppointmentSelected.extendedProps.statusId
                  ).label
                }}
              </b-button>
            </div>
          </div>
          <div class="col-5 d-flex justify-content-end">
            <b-button-group vertical class="align-self-stretch">
              <button
                v-if="isQueuePayment"
                id="btnCancelPayment"
                class="btn btn-warning"
                :disabled="!isTodayApt"
              >
                <i class="fas fa-redo"></i>
                <br />ยกเลิก <br />ชำระเงิน
              </button>
              <button
                v-else
                id="btnPaymentHistory"
                class="btn btn-success"
                title="บันทึกการรักษาได้ เมื่ออยู่ในคิวรอตรวจเท่านั้น"
                :disabled="!isTodayApt || !isCheckIn"
              >
                <i class="fa fa-save" aria-hidden="true"></i>
                <br />บันทึก <br />รักษา
              </button>
              <button id="btnPatientDetail" class="btn btn-info">
                <i class="fa fa-info-circle" aria-hidden="true"></i>
                <br />ประวัติ <br />คนไข้
              </button>
            </b-button-group>
          </div>
        </div>
      </div>
    </div>

    <Loading v-if="isLoading"></Loading>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "../main";

import CustomerModal from "@/components/modal/Customer";
import SearchCustomerModal from "@/components/modal/SearchCustomer";
import ProfileCustomerModal from "@/components/modal/ProfileCustomer";
import PaymentHistoryModal from "@/components/modal/PaymentHistory";
import NextVisitModal from "@/components/modal/NextVisit";
import TreatmentPlanModal from "@/components/modal/TreatmentPlan";
import CariesRiskModal from "@/components/modal/CariesRiskAssesment";

//** ห้ามเอา import bootstrap ออก ถ้าออกจะทำให้ popover error (คิดว่าถูกเรียกใช้ใน jquery) */
import bootstrap from "bootstrap";

import ColorSidebar from "@/components/menubar/ColorSidebar";

import Dialog from "@/components/modal/Dialog";
import Notification from "@/components/Notification";
import Loading from "@/components/Loading";

import moment from "moment";
import fullCalendar from "@fullcalendar/vue";
import interaction, { Draggable } from "@fullcalendar/interaction";
import fcBootstrap from "@fullcalendar/bootstrap";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import locales from "@fullcalendar/core/locales/th";
import AdsCard from "@/components/Ads";
import MorFunDeeLogo from "../assets/img/morfundee-logo.png";

import $ from "jquery";
import "@/theme/DoctorDashboard.scss";

export default {
  name: "QueueManage",
  components: {
    CustomerModal,
    SearchCustomerModal,
    ProfileCustomerModal,
    PaymentHistoryModal,
    NextVisitModal,

    TreatmentPlanModal,
    CariesRiskModal,

    fullCalendar,
    Dialog,
    Notification,
    Loading,
    ColorSidebar,
    AdsCard,
  },
  data() {
    return {
      isLoading: false,
      appointmentTempData: null,
      themeSystem: "bootstrap",
      displayPage: false,
      dateDefault: moment().format("YYYY-MM-DD"),
      dateShower: false,
      schedulerLicenseKey: "0650622495-fcs-1679632464",
      locales: locales,
      slotLabelFormat: {
        hour: "2-digit",
        minute: "2-digit",
        omitZeroMinute: false,
        meridiem: "short",
      },
      slotDuration: "00:15",
      header: {
        left: "prevDay,todayButton,nextDay toggleSidebar",
        center: "title",
        right: "",
      },
      titleFormat: {
        month: "long",
        year: "2-digit",
        day: "numeric",
        weekday: "short",
      },
      calendarPlugins: [
        interaction,
        resourceTimeGridPlugin,
        fcBootstrap,
        momentTimezonePlugin,
      ],
      windowHeight: window.innerHeight,
      fetchEventTimeout: 0,
      tableHeaders: [
        {
          key: "index",
          label: "",
          class: "text-center",
        },
        {
          label: "คิวรอตรวจ",
          key: "title",
          tdClass: "ws-pre",
        },
        {
          label: "ข้อความ",
          key: "note",
        },
        {
          label: "สถานะ",
          sortable: true,
          key: "checkinStatus",
        },
        {
          label: "เวลานัด",
          sortable: true,
          key: "start",
        },
        {
          label: "เวลามาถึง",
          sortable: true,
          key: "checkinDt",
        },
      ],
      lastFcAppointmentSelected: null,
      isTodayApt: true,
      isCheckIn: true,
      popEl: null,
      isCalendarOpen: true,
      MorFunDeeLogo,
    };
  },
  created() {
    this.init();
  },
  watch: {
    dateDefault: function (date) {
      this.getDoctorAppointmentListByDate(date);
      this.setStateDoctorDate(date);
      this.getCalendarApi().gotoDate(date);
    },
    lastFcAppointmentSelected() {
      this.isTodayApt = moment().isSame(
        this.lastFcAppointmentSelected.start,
        "day"
      );
      this.isCheckIn =
        this.lastFcAppointmentSelected.extendedProps.statusId === 5;
    },
  },
  computed: {
    ...mapGetters({
      roomList: "moduleUser/getRoomList",
      branchInfo: "moduleUser/getBranchInfo",
      doctorAppointmentList: "moduleAppointment/getDoctorAppointmentList",
      doctorCheckInList: "moduleAppointment/getDoctorCheckInList",
      appointmentBy: "moduleAppointment/getDoctorAppointmentBy",
      getAppointmentStatusList: "moduleMaster/getAppointmentStatusList",
      getReminderList: "moduleMaster/getReminderList",
      userInfo: "moduleUser/getUserInfo",
    }),
    // slotDuration() {
    //   return this.branchInfo?.confApptDuration
    //     ? this.branchInfo.confApptDuration
    //     : "00:15";
    // },
    openingTime() {
      return this.branchInfo?.confOpeningTime
        ? this.branchInfo.confOpeningTime
        : "09:00";
    },
    closingTime() {
      return this.branchInfo?.confClosingTime
        ? this.branchInfo.confClosingTime
        : "21:00";
    },
    fullCalendarHeight() {
      return this.windowHeight - 80;
    },
    customButtons() {
      return {
        prevDay: {
          text: "prev",
          bootstrapFontAwesome: "fa-chevron-left",
          click: () => {
            this.dateDefault = moment(this.dateDefault)
              .add(-1, "days")
              .format("YYYY-MM-DD");
          },
        },
        todayButton: {
          text: "วันนี้",
          click: () => {
            this.dateDefault = moment().format("YYYY-MM-DD");
          },
        },
        nextDay: {
          text: "next",
          bootstrapFontAwesome: "fa-chevron-right",
          click: () => {
            this.dateDefault = moment(this.dateDefault)
              .add(1, "days")
              .format("YYYY-MM-DD");
          },
        },
        collapseCalendar: {
          text: "Collapse",
          bootstrapFontAwesome: "angle-double-up",
          click: () => {
            this.isCalendarOpen = !this.isCalendarOpen;
          },
        },
        toggleSidebar: {
          text: "toggle",
          bootstrapFontAwesome: "fa-sliders-h",
          click: () => {
            this.$refs.colorSidebar.toggle({ slotDuration: this.slotDuration });
          },
        },
      };
    },
    scrollTime() {
      return moment().format("HH:mm:ss");
    },
    getDoctorRooms() {
      const doctorAppointmentList = this.doctorAppointmentList;
      const roomList = this.roomList;
      let rooms = roomList.filter(room => {
        const found = doctorAppointmentList.findIndex(event => {
          return event.resourceId == room.id;
        });
        return found != -1;
      });

      if (rooms.length == 0) {
        return [
          {
            id: 0,
            title: "ไม่พบนัดหมาย",
          },
        ];
      } else {
        return rooms;
      }
    },
    isQueuePayment() {
      if (this.lastFcAppointmentSelected) {
        return this.lastFcAppointmentSelected.extendedProps.statusId === 6
          ? true
          : false;
      } else {
        return false;
      }
    },
    isNoQueue() {
      return this.doctorCheckInList.length === 0;
    },
    isToday() {
      return moment().isSame(this.dateDefault, "day");
    },
    tableCheckinList: {
      get() {
        if (this.doctorCheckInList.length === 0) {
          return [];
        } else {
          return this.doctorCheckInList.map(item => {
            return {
              id: item.id,
              title:
                item.extendedProps.patientName +
                "\n" +
                item.extendedProps.treatmentTitle,
              nameTh: item.extendedProps.patientName,
              dn: item.extendedProps.dn,
              txTitle: item.extendedProps.treatmentTitle,
              start: item.start,
              checkinDt: item.extendedProps.checkin.checkinDt,
              checkinStatus: item.extendedProps.checkin.checkinStatus,
              checkinStatusName: item.extendedProps.checkin.checkinStatusName,
              checkinNote: item.extendedProps.checkin.checkinNote,
              _rowVariant: "",
              blink: false,
            };
          });
        }
      },
    },
    isPatientOne() {
      return this.lastFcAppointmentSelected?.extendedProps?.patient?.id == 1;
    },
    isPatientNull() {
      return !this.lastFcAppointmentSelected?.extendedProps?.patient?.id;
    },
  },
  methods: {
    ...mapActions({
      setStateDoctorDate: "moduleAppointment/setStateDoctorDate",
      fetchDoctorAppointmentList:
        "moduleAppointment/fetchDoctorAppointmentList",
      fetchDoctorCheckInList: "moduleAppointment/fetchDoctorCheckInList",
      cancelWaitingForPaymentAppointment:
        "moduleAppointment/cancelWaitingForPaymentAppointment",
    }),
    init() {
      this.isLoading = true;

      this.getDoctorAppointmentListByDate(this.dateDefault);

      this.setStateDoctorDate(this.dateDefault);
    },
    offJQuery() {
      $(document).off("click", "#btnMorFunDee");
      $(document).off("click", "#btnCopyLink");
      $(document).off("click", "#btnPaymentHistory");
      $(document).off("click", "#btnPatientDetail");
      $(document).off("click", "#btnCancelPayment");
      $(document).off("click", "#btnCreatePatient");
      $(document).off("click", "#btnSearchPatient");
      $(document).off("click.hidePopover");
    },
    onJQuery() {
      const self = this;
      $(document).on("click", "#btnPaymentHistory", () => {
        const appointment = this.appointmentBy(
          this.lastFcAppointmentSelected.id,
          "id"
        );
        self.openPaymentHistoryModal(appointment);
        self.hidePopOver();
      });
      $(document).on("click", "#btnCancelPayment", () => {
        const appointment = this.appointmentBy(
          this.lastFcAppointmentSelected.id,
          "id"
        );
        self.$refs.Dialog.showAlertConfirm(
          "ยกเลิกส่งชำระเงิน ?",
          "คุณต้องการยกเลิกการส่งชำระเงิน",
          "warning",
          "ใช่",
          "ไม่"
        ).then(result => {
          if (result.value) {
            self.isLoading = true;
            self
              .cancelWaitingForPaymentAppointment({
                id: appointment.id,
                clinicUrl: self.$route.params.clinicUrl,
                branchUrl: self.$route.params.branchUrl,
              })
              .finally(() => {
                self.isLoading = false;
              });
          }
        });

        // function ยกเลิกคิวชำระเงิน เข้ามาอยู่ใน คิวรอตรวจ แต่ *ห้ามลบ treatment และ appointmentDraft ที่เคยบันทึกไป
      });

      $(document).on("click", "#btnPatientDetail", () => {
        const patient = this.lastFcAppointmentSelected.extendedProps.patient;
        const appointment = this.appointmentBy(
          this.lastFcAppointmentSelected.id,
          "id"
        );
        self.openProfileCustomerModal(appointment, patient, true);
        self.hidePopOver();
      });
      $(document).on("click", "#btnMorFunDee", () => {
        window
          .open(
            this.lastFcAppointmentSelected.extendedProps
              .mfdAppointmentMeetingUrl + "&mode=dentist",
            "_blank"
          )
          .focus();
        self.hidePopOver();
      });
      $(document).on("click", "#btnCopyLink", async () => {
        try {
          await navigator.clipboard.writeText(
            `${this.lastFcAppointmentSelected.extendedProps.mfdClipboradText}`
          );
          eventBus.$emit("alertToast", {
            message: "บันทึกใส่คลิปบอร์ดสำเร็จ",
            variant: "success",
          });
        } catch (error) {
          console.error(error);
          eventBus.$emit("alertToast", {
            message: "บันทึกใส่คลิปบอร์ดไม่สำเร็จ",
            variant: "warning",
          });
        }
      });

      //close popover when click outside
      $(document).on("click.hidePopover", e => {
        if (
          self.popEl &&
          !$(self.popEl).is(e.target) &&
          $(self.popEl).has(e.target).length === 0 &&
          $(".popover").has(e.target).length === 0
        ) {
          self.hidePopOver();
        }
      });
    },
    getDoctorAppointmentListByDate(date, silent = false) {
      if (!silent) this.isLoading = true;
      if (!date) date = this.dateDefault;
      this.fetchDoctorAppointmentList({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        date: date,
        uid: this.userInfo.uid,
      })
        .then(res => {
          this.isLoading = false;
        })
        .catch(err => {
          this.isLoading = false;
        });
    },
    openCustomerModal(patient, appointment, readOnly) {
      this.$refs.customerModal.show(patient, appointment, readOnly);
    },
    openSearchCustomerModalNoSelect() {
      this.$refs.searchCustomerModal.show();
    },
    openProfileCustomerModal(appointment, patient, hideFooter, mode) {
      this.$refs.profileCustomerModal.show(
        appointment,
        patient,
        hideFooter,
        mode
      );
    },
    openPaymentHistoryModal(appointment) {
      this.$refs.paymentHistoryModal.show(appointment);
    },
    openNextVisitModal(apt, pt) {
      this.$refs.nextVisitModal.show(apt, pt);
    },
    openTreatmentSummaryModal(apt) {
      this.$refs.treatmentSummaryModal.show(apt);
    },
    renderCalendarEvents(info) {
      function shadeColor(color, percent) {
        var R = parseInt(color.substring(1, 3), 16);
        var G = parseInt(color.substring(3, 5), 16);
        var B = parseInt(color.substring(5, 7), 16);

        R = parseInt((R * (100 + percent)) / 100);
        G = parseInt((G * (100 + percent)) / 100);
        B = parseInt((B * (100 + percent)) / 100);

        R = R < 255 ? R : 255;
        G = G < 255 ? G : 255;
        B = B < 255 ? B : 255;

        R = Math.round(R / 10) * 10;
        G = Math.round(G / 10) * 10;
        B = Math.round(B / 10) * 10;

        var RR =
          R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
        var GG =
          G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
        var BB =
          B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

        return "#" + RR + GG + BB;
      }

      let eventProps = info.event.extendedProps;
      const fcTime = info.el.getElementsByClassName("fc-time")[0];

      if (eventProps.patient?.id) {
        fcTime.classList.add("sideStatus");

        if (eventProps.statusId === 7) {
          info.el.style.backgroundColor = "#e0e0e0";
        } else if (eventProps.patient.id === 1) {
          info.el.classList.add("fc-unnamed-patient");
          info.el.style.backgroundColor = shadeColor(
            eventProps.doctorColor,
            -5
          );
        } else {
          info.el.style.backgroundColor = eventProps.doctorColor;
        }
        info.el.style.borderColor = eventProps.doctorColorBorder;
        fcTime.style.backgroundColor = eventProps.statusColor;
      } else {
        info.el.classList.add("fc-note-event");
        info.el.style.backgroundColor = "#5a6268";
        fcTime.style.display = "none";
      }

      if (eventProps.rmdId) {
        let rmdObj = this.findReminderObj(eventProps.rmdId);
        $(info.el.querySelector(".fc-title")).prepend(
          `<span class="mr-1 px-1 rounded" style="color: white; background-color: ${rmdObj.desc}">${rmdObj.code}</span>`
        );
      }
    },
    onViewSkeletonRender() {
      $("#calendar .fc-view-container").css({ "overflow-x": "auto" });
      $("#calendar .table-bordered").css(
        "min-width",
        $(".fc-resource-cell").length * 110
      );
    },
    fcAppointmentClick(info) {
      this.lastFcAppointmentSelected = Object.assign(info.event);
      this.popEl = info.el;
      this.hidePopOver();
      Vue.nextTick().then(function () {
        $(info.el)
          .popover({
            content: () => $("#appointmentPopover").html(),
            placement: "left",
            boundary: "scrollParent",
            boundaryPadding: 5,
            delay: { show: 500, hide: 100 },
            offset: 0,
            triggers: "",
            html: true,
            container: "body",
            sanitize: false,
          })
          .popover("show");
      });
    },
    fcWindowResize(arg) {
      this.windowHeight = window.innerHeight;
    },
    hidePopOver() {
      $(".popover").not(this).popover("hide");
    },
    getCalendarApi() {
      return this.$refs.calendar.getApi();
    },
    checkTimeAppointment(data) {
      return data.checkinStatus != "04" ? this.formatTime(data.start) : "-";
    },
    formatTime(date) {
      return moment(date).format("HH:mm");
    },
    setToday() {
      this.dateDefault = moment().format("YYYY-MM-DD");
    },
    showDialogToast(variant, textDetail, noAutoHide) {
      this.$refs.Dialog.showToast(variant, textDetail, noAutoHide);
    },
    rowClick(row, index) {
      if (!this.isToday) return;

      const appointment = this.appointmentBy(row.id, "id");
      this.openPaymentHistoryModal(appointment);
    },
    setBgColor() {
      this.tableCheckinList.forEach(item => {
        let diff = moment().diff(item.checkinDt, "minutes");
        if (diff <= 8) {
          item._rowVariant = "green";
        } else if (diff <= 15) {
          item._rowVariant = "lightGreen";
        } else if (diff <= 30) {
          item._rowVariant = "yellow";
        } else if (diff <= 40) {
          item._rowVariant = "lightRed";
        } else if (diff <= 45) {
          item._rowVariant = "red";
        } else if (diff > 45) {
          item._rowVariant = "purple";
        }
      });
    },
    findStatusName(statusId) {
      return this.getAppointmentStatusList.find(function (item) {
        return item["code"] == statusId;
      });
    },
    findReminderObj(rmdId) {
      return this.getReminderList.find(function (item) {
        return item["val"] == rmdId;
      });
    },
    blinkMe(e) {
      let index = this.tableCheckinList.findIndex(i => i.id == e.id);
      if (index == -1) return;
      let item = this.tableCheckinList[index];
      item.blink = true;
      this.$set(this.tableCheckinList, index, item);

      setTimeout(() => {
        let index = this.tableCheckinList.findIndex(i => i.id == e.id);
        if (index == -1) return;
        let item = this.tableCheckinList[index];
        item.blink = false;
        this.$set(this.tableCheckinList, index, item);
      }, 6000);
    },
    rowClass(item, type) {
      let isBlink, isCursorNotAllowed;
      if (!item || type !== "row") return;
      item.blink ? (isBlink = "blink") : (isBlink = "");
      this.isToday
        ? (isCursorNotAllowed = "")
        : (isCursorNotAllowed = "cursor-not-allowed");
      return isBlink + " " + isCursorNotAllowed;
    },
    calAge(dateString) {
      if (!dateString) return;
      const date = moment(dateString, "YYYY-MM-DD");
      return moment().diff(date, "years");
    },
  },
  mounted() {
    this.onJQuery();
    eventBus.$on("openSearchCustomerModal", () => {
      this.$refs.searchCustomerModal.show();
    });
    eventBus.$on("slotDurationSetting", e => {
      this.slotDuration = e;
    });
    this.slotDuration =
      localStorage.getItem("fcSlotDuration") ||
      this.branchInfo.confApptDuration ||
      "00:15";

    eventBus.$on("blinkNewCheckIn", this.blinkMe);
    this.interval = setInterval(() => this.setBgColor(), 60000);

    eventBus.$on("fetch-queue-data", async () => {
      await this.$nextTick();
      this.getDoctorAppointmentListByDate(this.dateDefault, true);
    });
  },
  destroyed() {
    this.offJQuery();
    this.hidePopOver();
    eventBus.$off("openSearchCustomerModal");
    eventBus.$off("slotDurationSetting");
    eventBus.$off("blinkNewCheckIn");
    eventBus.$off("fetch-queue-data");

    clearInterval(this.interval);
  },
  updated() {
    this.setBgColor();
  },
};
</script>

<style></style>
